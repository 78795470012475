$(document).on('turbolinks:load', () => {
  const id = $('meta[property="fb:content_id"]').attr('content')
  const group = $('meta[property="fb:content_group"]').attr('content')

  $("a[data-fb-addtocart]").click((event) => {
    const ticketPrice = $(event.target).data().ticketPrice
    fbq('track', 'AddToCart', {
      content_ids: [id],
      content_type: group,
      value: ticketPrice,
      currency: 'USD',
    })

    gtag("event", "begin_checkout", {
      currency: "USD",
      value: ticketPrice,
      items: [
        {
          item_id: id,
          currency: "USD",
          price: ticketPrice,
        },
      ]
    })
  })

  fbq('track', 'ViewContent', {
    content_ids: [id],
    content_type: group,
  })
})
