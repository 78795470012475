import 'jquery'
import 'jquery-ujs'
import 'tether'
import 'bootstrap'

import '../javascript/main'

// Styles
// import '../sass/main'

import 'turbolinks'

import * as Rollbar from 'rollbar'

var rollbarConfig = {
  accessToken: "39d177b363df493fbdb21f77bca564a1",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        // code_version: process.env.CURRENT_GIT_SHA,
        guess_uncaught_frames: true
      }
    },
    environment: process.env.NODE_ENV
  }
};

window.Rollbar = new Rollbar(rollbarConfig)
window.onpopstate = () => { window.location.reload() }

// Support component names relative to this directory:
var componentRequireContext = require.context("../components", true, /^\.\/[^~]*$/)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

