$(document).on("turbolinks:load", () => {
  $(".buy-link").each((idx, el) => {
    jqel = $(el)
    withfriendsEventId = jqel.attr("withfriends-event-id")
    if(withfriendsEventId !== '') {
      if (jqel.withfriends) {
        jqel.withfriends(withfriendsEventId, {Replace_Element: false})
      }
    }
  })

  $('.become-a-member-link').each((idx, el) => {
    jqel = $(el)
    if (jqel.withfriends) {
      jqel.withfriends('indexical', {
        Replace_Element: false,
        Memberships: true,
      })
    }
  })
})
