import mixpanel from 'mixpanel-browser'

$(document).on('turbolinks:load', async () => {
  mixpanel.init('0934e8b3fc029d2b66ec58a689b34c37', {
    'property_blacklist': ['mc_eid', 'mc_cid']
  })
  window.mixpanel = mixpanel

  window.mixpanel.track('Viewed Page')

  const urlParams = new URLSearchParams(location.search)
  if (urlParams.has('mc_eid')) {
    for (const [key, value] of urlParams) {
      if (key === 'mc_eid') {
        var response = await $.get('/mailchimp/subscribers/identify', {
          mc_eid: value,
        })

        if (response.email) {
          window.mixpanel.alias(response.email)
          window.mixpanel.people.set({'Email': response.email})
        }
      }
    }
  }

  $('a[data-outbound-type="buy-tickets"]').click(e => {
    const label = $(e.target).data().outboundLabel
    window.mixpanel.track('Clicked Buy Tickets', {
      'label': label,
    })
  })

  return null
})
