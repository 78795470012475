if (!window.Withfriends) // Connect-your-store by hand backwards compatibility
	Withfriends = {jQueryVar: null}; 

Object.assign(Withfriends, {

		// Connect-your-store by hand backwards compatibility
		  Set_jQuery(jQueryVar) {
			Withfriends.jQueryVar = jQueryVar;
		}

		// Attach withfriends() function
		, Initialize_JQuery: function () {

			if (!Withfriends.jQueryVar)
				Withfriends.jQueryVar = $;

			if (Withfriends.jQueryVar){
				Withfriends.jQueryVar.fn.extend({
					withfriends: function(Target_ID, Options){

						if (Options.Replace_Element !== false)
								Options.Replace_Element = true;

						if (Options.Popup !== false)
								Options.Popup = true;

						var Target_URL = Withfriends.Get_Base_URL(Target_ID, Options);

						var Anchor_Element = null;					

						// Create button and set click element to inner A
						if (Options.Replace_Element) { 
							Withfriends.Create_Button(this, Target_ID, Options);
							$Anchor = this.find("a");
						}

						// Otherwise, if this is an anchor, set the HREF, but consider it the click element
						else { 
							// Set link behavior (if anchor)
							if (this.prop("tagName") == "A") { 
								this.attr("href", Target_URL);
							}
							$Anchor = this;
						}

						// If this is popup, add appropriate event handlers
						if (Options.Popup) {
							// Add handler
							$Anchor.click(
								function(Click_Event) {
									Withfriends.Open_Popup(this, Click_Event, Target_ID, Options);
								}
							);
						}

						// If this is not a popup, if the top element is an anchor, create normal button behavior on it, otherwise, create a click handler on the click element
						else { 
							if (this.prop("tagName") == "A") { 
								this.attr("href", Target_URL)
							}
							else {
								$Anchor.click(function(){window.location = Target_URL;return false;})
								.css("cursor", "pointer")
							}
						}

						// Mark processed
						$Anchor.attr('data-withfriends', 1);
					}
				});
			}
		}

	, Get_Base_URL: function(Target_ID, Options) { 
			// Create URL
			if (Options.Dev_Mode) {
				var Host = "https://dev.better.space"
			}
			else if (Options.Test_Mode) {
				var Host = "https://sandbox.withfriends.cool"
			}
			else { 
				var Host = "https://withfriends.co";
			}

			if (Options.Memberships) {
				return Host + "/jelly/html/build/embed.html?organization=" + Target_ID
				// return (Host + "/" + "movement" + "/" + Target_ID);
			}
			else {
				return (Host + "/" + "event" + "/" + Target_ID);	
			}
		}

	, Create_Button: function(Button_Element, Event_ID, Options) {
			// Empty button
			$Button = Withfriends.jQueryVar(Button_Element).html("");

			// Convert to positioned
			if ($Button.css("display") == "inline")
				$Button.css("display", "inline-block");
			$Button.css("position", "relative");

			// Size
			$Button.css("width", "304px").css("height", "52px");

			// Add IFrame
			var IFrame_URL = Withfriends.Get_Base_URL(Event_ID, Options) + "/" + "embed" + "/" + "raw:No_Links";
			$Button.append(
				Withfriends.jQueryVar("<iframe/>").attr("src", IFrame_URL).attr("width", 304).attr("height", 52).attr("frameborder", "no").attr("sandbox", "allow-forms allow-same-origin allow-scripts allow-top-navigation")
			);

			// Add anchor
			$Button.append(
				Withfriends.jQueryVar("<a/>")
					.css("position", "absolute")
					.css("width", "100%")
					.css("height", "100%")
					.css("top", "0px")
					.css("left", "0px")
					.css("zIndex", 1)
					.css("cursor", "pointer")
			)
		}
		
		// 
	, Open_Popup: function(Click_Element, Click_Event, Target_ID, Options, Target_URL = null) {
			Withfriends.Lock();

			// Handle pop-up
			if (Click_Event)
				Click_Event.preventDefault();

			let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
			let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

			var Ideal_Size = {
					width: 800
				, height: 619
			};

			var Mobile_Width = 480;

			var Margin = (windowWidth <= Mobile_Width ? 16 : 48);

			var Popup_Size = {};

			if (Ideal_Size.width > (windowWidth - (Margin * 2)))
				Popup_Size.width = (windowWidth - (Margin * 2));
			else 
				Popup_Size.width = Ideal_Size.width;

			if (Ideal_Size.height > (windowHeight - (Margin * 2)))
				Popup_Size.height = (windowHeight - (Margin * 2));
			else 
				Popup_Size.height = Ideal_Size.height;

			// Create link
			if (!Target_URL) {
				// Target_URL = Withfriends.Get_Base_URL(Target_ID, Options) + "/" + "popup" + (Options.Tier ? ":Tier=" + Options.Tier : "");
				if (Options.Memberships)
					Target_URL = Withfriends.Get_Base_URL(Target_ID, Options) + (Options.Tier ? "&tier=" + Options.Tier : "");
				else
					Target_URL = Withfriends.Get_Base_URL(Target_ID, Options) + "/" + "popup";
			}

			// Open to link
			if (!Withfriends.$Lightbox) {
				Withfriends.$Lightbox = Withfriends.jQueryVar('<div/>')
					// .css("background-color", "rgba(0,0,0,.8)")
					// .css("background-color", "rgba(171, 135, 253, 0.7)")
					// .css("background-color", "rgba(255, 255, 255, 0.7)")
					.css("background-color", "rgba(0, 0, 0, 0.5)")
					.css("position", "fixed")
					.css("left", "0px")
					.css("top", "0px")
					.css("width", "100vw")
					.css("height", "100vh")
					.css("display", "none")
					.css("z-index", "10000")
				;
				Withfriends.jQueryVar('body').append(Withfriends.$Lightbox);
				Withfriends.$Lightbox.fadeIn("fast");
			}

			Withfriends.$Popup = Withfriends.jQueryVar("<div/>")
				.css("position", "fixed")
				.css("border", "none")
				.css("background", "white")
				.css("left", "calc((100vw - " + (Popup_Size.width + 6 + "px") + ")/2)")
				// .css("top", "calc((100vh - " + (Popup_Size.height + "px") + ")/2)")
				.css("top", ((windowHeight - Popup_Size.height) / 2) + "px")
				.css("width", Popup_Size.width + 0 + "px")
				.css("height", Popup_Size.height + "px")
				.css("z-index", "2147483647")
				.css("overflow-x", "hidden")
				.css("box-sizing", "border-box")
			;
			Withfriends.$Popup.append(
				Withfriends.jQueryVar("<iframe/>")
					.attr("src", Target_URL)
					.css("border", "none")
					.css("width", Popup_Size.width + 0 + "px")
					.css("height", Popup_Size.height + "px")
					.css("background", 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%23aaaaaa" x="50%" y="50%" font-family="Arial, sans-serif" font-size="18" text-anchor="middle">Loading...</text></svg>\') 0px 0px no-repeat')
			);
			Withfriends.jQueryVar('body').append(Withfriends.$Popup);

			window.addEventListener("message", function(Message_Event){
				// console.log(Message_Event);
				if (Message_Event.data.action == "resize" && Message_Event.data.value)
					Withfriends.Resize_Popup(Message_Event.data.value);

				if (Message_Event.data.action == "close")
					Withfriends.Close_Popup();
				
				if (Message_Event.data.action == "confirmation") {
					// Create URL
					let flowUrl;
					if (Options.Dev_Mode)
						flowUrl = "https://dev.better.space"
					else if (Options.Test_Mode)
						flowUrl = "https://sandbox.withfriends.cool"
					else
						flowUrl = "https://withfriends.co";
					flowUrl += "/action/post_checkout_popup" + ":" + Withfriends.jQueryVar.param(Message_Event.data.orderInformation).replace(/&/g, ",");
					if (Withfriends.$Popup) {
						Withfriends.$Popup.remove();
						Withfriends.$Popup = null;
					}
					Withfriends.Open_Popup(null, null, null, null, flowUrl);
				}
			}, false);
		}
		, Close_Popup: function() { 
			Withfriends.Unlock();
			Withfriends.$Lightbox.fadeOut("fast", function() { 
				Withfriends.$Lightbox.remove();
				delete Withfriends.$Lightbox;
			});
			Withfriends.$Popup.fadeOut("fast", function() { 
				Withfriends.$Popup.remove();
			});
		}
		, Resize_Popup: function(Column_Height) { 

				var Mobile_Width = 480;

				let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
				let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

				// Don't resize mobile
				if (windowWidth > Mobile_Width) { 
					var Margin = (windowWidth <= Mobile_Width ? 16 : 48);
					var New_Height = parseInt(Column_Height) + 175;
					if (New_Height > (windowHeight - (Margin * 2)))
						New_Height = (windowHeight - (Margin * 2));

					var New_Size = {
							height: New_Height + "px"
						, top: ((windowHeight - New_Height)) / 2 + "px"
					}
					Withfriends.$Popup.animate(New_Size, 300).find("iframe").animate(New_Size, 300);
				}
			}
		, Lock: function(){ 
			// Create lock style
			Withfriends.jQueryVar("<style type='text/css'/>").attr("id", "wf-embed-lock-style").html('html.withfriends-embed-lock, body.withfriends-embed-lock {overflow:hidden; /*height:100%;*/ position:relative;}').appendTo("head");
			Withfriends.jQueryVar('html, body').addClass('withfriends-embed-lock');
		}
		, Unlock: function() { 
			Withfriends.jQueryVar("#wf-embed-lock-style").remove();
			Withfriends.jQueryVar('html, body').removeClass('withfriends-embed-lock');
		}

		, Upgrade_Buttons: (Target, Memberships, clearOnSuccess = false, host = "withfriends.co") => {
			if (Withfriends.jQueryVar('a[href*="/' + host + '/"]:not([data-withfriends=1])').length){
  				$Anchor_Elements = Withfriends.jQueryVar('a[href*="/' + host + '/"]');
  				$Anchor_Elements.each((Anchor_Index, Anchor_Element) => {
  					let $Anchor_Element = Withfriends.jQueryVar(Anchor_Element);
  					let Membership_Parameters = {Replace_Element: false, Memberships: true};
  					if ($Anchor_Element.prop("href").includes("membership_id=")) {
  						Membership_Parameters.Tier = $Anchor_Element.prop("href").split("membership_id=")[1].split(/,\?/)[0];
  						Membership_Parameters.Auto_Select = true;
  					}
  					switch (host) {
  						case "www.withfriends.co":
  						case "withfriends.co":
  							break;

  						case "sandbox.withfriends.cool":
  							Membership_Parameters.Test_Mode = true;
  							break;

  						case "dev.better.space":
  						default:
  							Membership_Parameters.Dev_Mode = true;
  							break;

  					}
  					$Anchor_Element.withfriends(Target, Membership_Parameters);
  				})

  				if (clearOnSuccess) {
	    			clearInterval(Withfriends.membershipBackupInterval);
	    			delete Withfriends.membershipBackupInterval;
	    		}
			}
		}

		, Start: (Target, Memberships = true, host = "withfriends.co") => {
    		Withfriends.membershipBackupInterval = setInterval(() => {
	      			Withfriends.Upgrade_Buttons(Target, Memberships, false, host);
	  			}
	  			, 500
	  		);

  		}
});

// Connect-your-store by hand backwards compatibility
Withfriends.Initialize_jQuery = Withfriends.Initialize_JQuery;
Withfriends.Initialize_JQuery();