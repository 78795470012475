$(document).on("turbolinks:load", () => {
  if (!!Twitch) {
    if (!!document.getElementById('twitch-embed')) {
      new Twitch.Embed('twitch-embed', {
        width: "100%",
        height: "100%",
        channel: 'anindexofmusic',
        layout: 'video',
        parent: ['www.indexical.org', 'indexical.org', 'dev.indexical.org']
      })
    }
  }
})
