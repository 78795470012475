const loadImg = (ev) => {
    ev.target.parentElement.classList.remove('d-none')
    ev.target.parentElement.parentElement.style.paddingBottom = 0
}

$(document).on("turbolinks:load", () => {
    $('.img-real img').one('load', loadImg).each(function() {
        if (this.complete) {
            $(this).trigger('load')
        }
    })
})
