$(document).on('turbolinks:load', () => {
  const formAmount = document.getElementById("amount")
  for (e of document.getElementsByClassName("set-donate")) {
    e.addEventListener("click", function(event) {
      formAmount.value = event.target.dataset.donation
    })
  }
  
  for (e of document.getElementsByClassName("set-donate-surprise")) {
    e.addEventListener("click", function(event) {
      formAmount.value = Math.floor(Math.random() * 1000)
    })
  }  
})